/* ==================================================================================================================
 * OpenGoSim Bluebell: app/environments/environment.ts-ogs.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
export const environment = {
  postLoginPage: ['/projects'],
  production: true,
  restUrl: 'api/',
  server: '',
  webSocketProtocol: 'wss',

  // Cognito
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_Mj2o1jNyt',
  clientId: '35ng36ei23ogqahd43bitvbrlf',
};
